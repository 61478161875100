<template>
  <el-dialog
    width="600px"
    class="package-form"
    :title="isEdit ? 'Chỉnh sửa thông tin' : 'Thêm mới phần tử'"
    :visible="visible"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <el-form ref="form" :model="form" label-position="top" :rules="rules">
      <el-form-item label="Đối tượng" prop="hierarchy_type">
        <el-select v-model="form.type" class="w-100" @change="handleChangeType">
          <el-option
            v-for="c in placeTypes"
            :key="c.value"
            :label="c.name"
            :value="c.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="!isEdit" label="Cấp bậc" prop="hierarchy_type">
        <el-select v-model="form.isChildren" class="w-100">
          <el-option
            label="Liên kết"
            :disabled="!!parent.secondPerson"
            :value="false"
          ></el-option>
          <el-option
            label="Cấp dưới"
            :value="true"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Phần tử" prop="based_id">
        <el-select
          v-model="form.based_id"
          class="w-100"
          filterable
          remote
          :placeholder="`Chọn ${getPlaceType(type)}`"
          :remote-method="searchObjectByType"
          :loading="loading"
          :disabled="callingAPI"
        >
          <el-option
            v-for="p in baseds"
            :key="p.id"
            :label="p.name"
            :value="p.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Mô tả" prop="description">
        <el-input v-model="form.description" type="textarea" placeholder="Nhập vào mô tả"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer" :class="{'is-edit': isEdit}">
      <el-button v-if="isEdit" type="danger" :loading="callingAPI" @click="handleDeleteItem">Xóa</el-button>
      <el-row type="flex">
        <el-button :disabled="callingAPI" @click="handleClose">Hủy</el-button>
        <el-button type="primary" :loading="callingAPI" @click="handleUpdateItem">
          {{ isEdit ? 'Cập nhật' : 'Thêm mới' }}
        </el-button>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPlaceType } from '@/utils/filters'
import { listPlaces } from '@/services/place'
import {
  createHierarchyItem,
  updateHierarchyItem,
  deleteHierarchyItem
} from '@/services/hierarchy-item'

export default {
  name: 'ItemForm',
  props: {
    hierarchy: Object,
    parent: Object,
    visible: Boolean,
    isEdit: Boolean,
    type: String,
    placeTypes: Array,
    selectedItems: Array
  },
  data() {
    return {
      loading: false,
      searchText: '',
      callingAPI: false,
      places: [],
      form: {
        based_id: this.isEdit ? this.parent.based_id : null,
        isChildren: true,
        type: this.parent.place_type,
        description: this.isEdit ? this.parent.description : null
      },
      rules: {
        based_id: [
          { required: true, message: 'Phần tử con không được để trống', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    currentType() {
      return this.placeTypes.find((t) => t.value === this.type)
    },
    placeType() {
      return this.currentType.type
    },
    params() {
      return {
        locale: this.language,
        place_types: this.form.type,
        page: 1,
        per_page: 10,
        search_text: this.searchText
      }
    },
    baseds() {
      let allItems = this.places
      if (this.isEdit) {
        if (!allItems.find((item) => item.id === this.parent.based_id)) {
          allItems = [{ ...this.parent, id: this.parent.based_id }, ...allItems]
        }
        return allItems.filter((item) => item.id === this.parent.based_id || ![...this.selectedItems.map((i) => i.based_id), this.hierarchy.based_id].includes(item.id))
      }
      return allItems.filter((item) => ![...this.selectedItems.map((i) => i.based_id), this.hierarchy.based_id].includes(item.id))
    },
    itemPath() {
      if (this.isEdit) {
        return this.parent.path
      }
      if (!this.form.isChildren) {
        const parentPath = `${this.parent.pathLevels.slice(0, this.parent.pathLevels.length - 1).join('.')}`
        return parentPath ? `${parentPath}.${this.parent.sameLevels.length + 1}` : `${this.parent.sameLevels.length + 1}`
      }
      if (this.parent.path) {
        return `${this.parent.path}.${this.parent.children.length + 1}`
      }
      return `${this.parent.children.length + 1}`
    },
    linkPath() {
      if (this.isEdit) {
        return this.parent.link_path
      }
      return this.form.isChildren ? '' : this.parent.path
    },
    dataInput() {
      const base = this.baseds.find((b) => b.id === this.form.based_id)
      const description = {
        description: this.form.description,
        link_path: this.linkPath
      }
      const data = {
        locale: this.language,
        based_type: this.placeType,
        based_id: this.form.based_id,
        hierarchy_id: this.hierarchy.id,
        image_url: '',
        path: this.itemPath,
        status: base ? base.status : 'private',
        name: base ? base.name : '',
        description: JSON.stringify(description)
      }
      return this.isEdit ? {
        id: this.parent.id,
        ...data
      } : data
    }
  },
  beforeMount() {
    if (!this.isEdit) {
      this.loadData()
    }
  },
  methods: {
    getPlaceType,
    loadData() {
      this.loading = true
      listPlaces(this.params).then((res) => {
        this.places = res.result
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    handleChangeType(value) {
      this.form.type = value
      this.form.based_id = null
      this.loadData()
    },
    searchObjectByType(text) {
      this.searchText = text
      setTimeout(() => {
        this.loadData()
      }, 100)
    },
    handleUpdateItem() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isEdit) {
            updateHierarchyItem(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Sửa thông tin thành công'
              })
              this.$emit('close')
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          } else {
            createHierarchyItem(this.dataInput).then(() => {
              this.$notify.success({
                title: 'Thành công',
                message: 'Thêm mới phần tử thành công'
              })
              this.$emit('close')
              this.$emit('reload')
              this.callingAPI = false
            }).catch(() => {
              this.callingAPI = false
            })
          }
        }
      })
    },
    handleClose() {
      this.$refs.form.resetFields()
      this.$emit('close')
    },
    handleDeleteItem() {
      this.$confirm('Bạn có chắc chắn muốn xóa không?', 'Xác nhận', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Đóng',
        type: 'warning'
      }).then(() => {
        deleteHierarchyItem({ id: this.parent.id }).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa phần tử thành công'
          })
          this.$emit('close')
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thông báo',
            message: 'Không thể xóa.'
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
  &.is-edit {
    justify-content: space-between;
  }
}
</style>
